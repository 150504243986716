export const TOKEN = 'token';
export const CA_ID = 'caId';
export const REFRESH_TOKEN = 'refresh_token';
export const EXPIRED_REFRESH_TOKEN = 30 * 24 * 60 * 60 * 1000;
export const EXPIRED_TIME = 30 * 60 * 1000;
export const LONG_TRANSIT_DURATION = 5 * 3600 * 1000;
export const DOMAIN_GATE = 'https://gate.dev.tripi.vn';
export const BASE_URL_HOTEL_SERVICE = '/hotels';
export const BASE_URL_PAYMENT_SERVICE = '/payment';
export const BASE_URL_PROMOTION_SERVICE = '/promotion';
export const BASE_URL_AUTH_SERVICE = '/auth';
export const BASE_URL_ACCOUNT_SERVICE = '/account';
export const BASE_URL_AMS_SERVICE = '/ams';
export const BASE_URL_HMS_PREMIUM_SERVICE = '/hms-premium';
export const BASE_URL_CUSTOMER_ENGAGEMENT = '/customer-engagement';
export const BASE_URL_FLIGHT_SERVICE = 'https://dev-api.tripi.vn';
export const BASE_URL_MY_TOUR_SERVICE = 'https://api.mytour.vn';
export const BASE_URL = 'https://dev-api.tripi.vn';
export const BASE_URL_CONDITION_MYTOUR =
  'https://mytour.vn/news/135152-chinh-sach-va-quy-dinh-chung.html';
export const HOTEL_SERVICE = 'HOTEL_SERVICE';
export const FLIGHT_SERVICE = 'FLIGHT_SERVICE';
export const PAYMENT_SERVICE = 'PAYMENT_SERVICE';
export const PROMOTION_SERVICE = 'PROMOTION_SERVICE';
export const MY_TOUR_SERVICE = 'MY_TOUR_SERVICE';
export const AUTH_SERVICE = 'AUTH_SERVICE';
export const ACCOUNT_SERVICE = 'ACCOUNT_SERVICE';
export const AMS_SERVICE = 'AMS_SERVICE';
export const HMS_PREMIUM_SERVICE = 'HMS_PREMIUM_SERVICE';
export const CUSTOMER_ENGAGEMENT = 'CUSTOMER_ENGAGEMENT';
export const ASSETS_SERVICE = 'ASSETS_SERVICE';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const GATE = 'GATE';
export const CHECKOUT_PAGE = 'CHECKOUT_PAGE';
export const BACKEND = 'BACKEND';
/////////CONFIG SERVER HOTEL SERVICE///////////////

export const IS_MYTOUR = window.location.hostname.includes('mytour');
export const IS_DINOGO = window.location.hostname.includes('dinogo');
export const APP_ID = IS_MYTOUR ? 'mytour-web' : 'vntravel-web';
export const HASH_KEY_DEV = IS_MYTOUR
  ? '95b9699c-25e5-4811-bb7e-5d9163bd2dd8'
  : '12321xssds21323w1232131231sdad';
export const HASH_KEY_PROD = IS_MYTOUR
  ? '@Zz8qt5CzUlyg#$RK4YJmW5!I@yYSaVf'
  : '123213ddd12342321ssdfqw41232131';
export const VERSION = '1.0';

/////////CONFIG GTM///////////////
export const GTM_CONFIG_DEV = {
  GTM_ID: 'GTM-5WXX2J5',
  GTM_DOMAIN: 'https://gtm-t55ccn8-odbkz.uc.r.appspot.com',
  MKT_CODE: 'VNT-ED6x0KUVGMLR',
  MKT_CORE: 'https://storage.googleapis.com/mkt-sdk/dev/vnt-mp-campaign.js',
};

export const GTM_CONFIG_PROD = {
  GTM_ID: 'GTM-5XW6PZ2',
  GTM_DOMAIN: 'https://gtm.tripi.vn',
  MKT_CODE: 'VNT-2aHwQeffEVm0',
  MKT_CORE: 'https://storage.googleapis.com/mkt-sdk/prod/vnt-mp.js',
};

/////////data save local storage ///////////
export const LAST_HOTEL_BROWSER_ITEMS = 'last-hotel-browser-items';
export const LAST_LOCATION_RECENT_SEARCH_HISTORY_ITEMS =
  'last-location-recent-search-history-items';
export const LAST_SEARCH_HISTORY = 'last-search-history';
export const LAST_HOTEL_FAVORITES = 'list-hotel-favorites';
export const LAT_LONG_USER = 'lat-long-user';
export const LAST_BOOKING_HOTEL = 'last-booking-hotel';
export const LAST_BOOKING_HOTEL_INFO = 'last-booking-hotel-info';
export const LAST_REGISTER_INFO = 'last-register-info';
export const LAST_FORGOT_PASSWORD_INFO = 'last-forgot-password-info';
export const SEARCH_RECENT = 'SEARCH_RECENT';
export const SEARCH_PREV = 'SEARCH_PREV';
export const LAST_AFFILIATE = 'last-affiliate';
export const LAST_DOWNLOAD_APP = 'last-download-app';
/////////data save local storage ///////////
export const DEVICE_ID_IN_SERVER = 'device_id_in_server';
////////////////////////////////////////////

export const PAYMENT_HOLDING_CODE = 'PL';
export const PAYMENT_TRIPI_CREDIT_CODE = 'CD';
export const PAYMENT_ATM_CODE = 'ATM';
export const PAYMENT_VISA_CODE = 'VM';
export const PAYMENT_VNPAY_CODE = 'QR';
export const PAYMENT_TRANSFER_CODE = 'DBT';
export const DELAY_TIMEOUT_POLLING = 500;
export const RADIUS_GET_HOTEL_LAT_LONG = 5;

export const FLIGHT_SERVICE_CHARGE = 20000;

export const DEV = process.env.NODE_ENV === 'development';

export const TEST = window.location.hostname.indexOf('-dev.') >= 0;

export const STAGING = window.location.hostname.includes('-stage');

export const listEventHotel = {
  HotelViewList: 'HotelViewList',
  HotelViewDetail: 'HotelViewDetail',
  HotelSearchBySlug: 'HotelSearchBySlug',
  HotelSearchByName: 'HotelSearchByName',
  HotelInitCheckout: 'HotelInitCheckout',
  HotelAddToCart: 'HotelAddToCart',
  HotelPurchase: 'HotelPurchase',
  HotelAddPaymentInfo: 'HotelAddPaymentInfo',
  HotelViewListPromo: 'HotelViewListPromo',
  HotelAddPromo: 'HotelAddPromo',
  HotelPurchaseCompleted: 'HotelPurchaseCompleted',
  HotelPurchaseFailed: 'HotelPurchaseFailed',
  HotelFiltered: 'HotelFiltered',
};

export const listEventFlight = {
  FlightViewListTicket: 'FlightViewListTicket',
  FlightViewDetail: 'FlightViewDetail',
  FlightSearchOneDirection: 'FlightSearchOneDirection',
  FlightSearchRoundTrip: 'FlightSearchRoundTrip',
  FlightInitCheckout: 'FlightInitCheckout',
  FlightAddToCart: 'FlightAddToCart',
  FlightPurchase: 'FlightPurchase',
  FlightAddPaymentInfo: 'FlightAddPaymentInfo',
  FlightAddInsurance: 'FlightAddInsurance',
  FlightViewListPromo: 'FlightViewListPromo',
  FlightAddPromo: 'FlightAddPromo',
  FlightPurchaseCompleted: 'FlightPurchaseCompleted',
  FlightPurchaseFailed: 'FlightPurchaseFailed',
};

export const listEventFlightGtm = {
  flight_engagement: 'flight_engagement', // done
  flight_search_ticket: 'flight_search_ticket', // done
  flight_filter_ticket: 'flight_filter_ticket', // done
  flight_view_ticket: 'flight_view_ticket', // done pc
  flight_input_passenger_info: 'flight_input_passenger_info', // done
  flight_add_service: 'flight_add_service', // done
  flight_initiate_checkout: 'flight_initiate_checkout', // done
  flight_add_promotion: 'flight_add_promotion', // done
  flight_purchase: 'flight_purchase', // done
  purchase: 'purchase',
};

export const optionClean = {
  allowedTags: [
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
    'iframe',
    'img',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: ['src', 'style', 'alt', 'width', 'height'],
    p: ['style'],
  },
  allowedClasses: {
    '*': ['text-uppercase', 'red'],
  },
};
export const formatDate = {
  firstYear: 'YYYY-MM-DD',
  firstDay: 'DD-MM-YYYY',
  dayAndMonth: 'DD/MM',
  placeholder: 'dd/mm/yyyy',
};

export const routeStatic = {
  HOME: {
    href: '/',
    as: '/',
  },
  HOTEL: {
    href: '/khach-san',
  },
  FLIGHT: {
    href: '/ve-may-bay',
    as: '/ve-may-bay',
  },
  BLOG: {
    href: '/location',
  },
  HOTEL_DETAIL: {
    href: '/khach-san/[alias]',
    as: '/khach-san/[alias]',
  },
  LISTING_HOTEL: {
    href: '/khach-san/[...slug]',
    as: '/khach-san/[...slug]',
  },
  TOP_PRICE_HOTEL: {
    href: '/khach-san/khach-san-gia-tot/[...slug]',
    as: '/khach-san/khach-san-gia-tot/[...slug]',
  },
  SIGN_UP: {
    href: '/sign-up',
  },
  FAVORITE_DESTINATIONS_HOTEL_LIST: {
    href: '/diem-den-yeu-thich',
  },
  FLIGHT_GOOD_PRICES: {
    href: '/ve-may-bay/chang-bay-gia-tot',
  },
  FLIGHT_LANDING: {
    href: '/ve-may-bay/[...slug]',
  },
  FLIGHT_LISTING_RESULT: {
    href: '/ve-may-bay/listing',
  },
  HOTEL_HOT_DEALS: {
    href: '/khach-san-gia-tot',
  },
  TOP_HOTEL: {
    href: '/top-khach-san-hang-dau',
  },
  BOOKING_HOTEL: {
    href: '/khach-san/dat-phong',
  },
  PAYMENT_HOTEL: {
    href: '/khach-san/thanh-toan',
  },
  RESULT_PAYMENT_BOOKING: {
    href: '/khach-san/ket-qua-dat-phong/[transactionId]',
  },
  ACCOUNT: {
    href: '/tai-khoan',
  },
  ACCOUNT_ORDER_BOOKING: {
    href: '/tai-khoan/don-phong',
  },
  ACCOUNT_ORDER_BOOKING_DETAIL: {
    href: '/tai-khoan/don-phong/[id]',
  },
  ACCOUNT_ORDER_FLIGHT: {
    href: '/tai-khoan/ve-may-bay',
  },
  ACCOUNT_ORDER_FLIGHT_DETAIL: {
    href: '/tai-khoan/ve-may-bay/[id]',
  },
  ACCOUNT_FAVORITES_HOTEL: {
    href: '/tai-khoan/khach-san-yeu-thich',
  },
  ACCOUNT_INFO: {
    href: '/tai-khoan/doi-thong-tin',
  },
  ACCOUNT_FORGOT_PASSWORD: {
    href: '/tai-khoan/quen-mat-khau',
  },
  ACCOUNT_CHANGE_PASSWORD: {
    href: '/tai-khoan/doi-mat-khau',
  },
  REGISTER_ACCOUNT: {
    href: '/dang-ky',
  },
  REGISTER_ACCOUNT_STEP_2: {
    href: '/dang-ky/nhap-otp',
  },
  REGISTER_ACCOUNT_STEP_3: {
    href: '/dang-ky/nhap-otp/hoan-thanh',
  },
  LOGIN: {
    href: '/dang-nhap',
  },
  FORGET_PASSWORD: {
    href: '/quen-mat-khau',
  },
  FORGET_PASSWORD_STEP_2: {
    href: '/quen-mat-khau/nhap-otp',
  },
  FORGET_PASSWORD_STEP_3: {
    href: '/quen-mat-khau/nhap-otp/hoan-thanh',
  },
  TOP_HOTEL_BRAND: {
    href: '/thuong-hieu-khach-san-hang-dau',
  },
  REPAY_BOOKING_FLIGHT: {
    href: '/ve-may-bay/payment/paymentBookingHolding',
  },
  PARTNERSHIP: {
    href: '/partnership',
  },
  DOWNLOAD_APP: {
    href: '/tai-ung-dung',
  },
  NOTIFICATIONS: {
    href: '/thong-bao',
  },
  DETAIL_NOTIFICATION: {
    href: '/thong-bao/chi-tiet',
  },
};

export const hotLine = {
  hotelHn: '024.7109.9999',
  hotelHcm: '028.7109.9998',
  flight: '1900 2083',
};

export const LINK_DOWLOAD_APP = {
  ios: 'https://apps.apple.com/us/app/mytour-%C4%91%E1%BA%B7t-ph%C3%B2ng-v%C3%A9-m%C3%A1y-bay/id1149730203',
  android:
    'https://play.google.com/store/apps/details?id=vn.mytour.apps.android&hl=vi&gl=US',
};

export const EMAIL_HOTLINE = {
  HOTEL: 'info@mytour.vn',
  MARKETING: 'marketing@mytour.vn',
  SUPPORT: 'hotro@mytour.vn',
};
export const TRAVEL_TYPE = [
  { name: 'Công tác', type: 'business' },
  { name: 'Cặp đôi', type: 'couple' },
  { name: 'Gia đình', type: 'family' },
  { name: 'Bạn bè', type: 'friend' },
  { name: 'Cá nhân', type: 'single' },
  { name: 'Nhóm', type: 'group' },
  { name: 'Khác', type: 'other' },
];

export const DIALOG_MESSAGE_TYPE = {
  ALERT: 1,
  HTML: 2,
};

export const socialAccount = {
  // local
  // gg_plus_id:
  //   "150457992313-n3grbvu122vsgi5kc962r4rc7jdhqds0.apps.googleusercontent.com",
  // facebook_id: "659508064601650",
  gg_plus_id:
    '701699179758-9tb2gjluvjnu2m218tka373fv62cq8rq.apps.googleusercontent.com',
  facebook_id: '857393964278669',
};

export const IMAGE_LOADING_BOOK_TICKET =
  'https://storage.googleapis.com/tripi-assets/mytour/banner/loading_flight.gif';

/**
 * Messages code display price
 */

/**
 * Danh sách loại ghế
 */
export const SEAT_TYPE_LIST = [
  {
    cid: 1,
    name: 'Phổ thông',
    description: 'Bay tiết kiệm, đáp ứng mọi nhu cầu cơ bản',
  },
  {
    cid: 2,
    name: 'Phổ thông đặc biệt',
    description: 'Chi phí hợp lý với bữa ăn ngon và chỗ để chân rộng rãi.',
  },
  {
    cid: 3,
    name: 'Thương gia',
    description: 'Bay đẳng cấp, với quầy làm thủ tục và khu ghế ngồi riêng.',
  },
  {
    cid: 4,
    name: 'Hạng nhất',
    description: 'Hạng cao cấp nhất, với dịch vụ 5 sao được cá nhân hoá.',
  },
];

/**
 * Danh sách loại ghế
 */
export const LIFT_TIME_LIST = [
  {
    id: 1,
    name: 'Sáng sớm',
    from: '00:00',
    to: '06:00',
  },
  {
    id: 2,
    name: 'Buổi sáng',
    from: '06:00',
    to: '12:00',
  },
  {
    id: 3,
    name: 'Buổi chiều',
    from: '12:00',
    to: '18:00',
  },
  {
    id: 4,
    name: 'Buổi tối',
    from: '18:00',
    to: '24:00',
  },
];

/**
 * Danh sách giá trị sắp xếp vé máy bay
 */
export const SORTER_FLIGHT_LIST = [
  {
    id: 1,
    name: 'Đề xuất',
  },
  {
    id: 2,
    name: 'Giá tăng dần',
  },
  {
    id: 3,
    name: 'Thời gian cất cánh',
  },
  {
    id: 4,
    name: 'Thời gian bay',
  },
];

/**
 * Số điểm dừng
 */
export const NUM_STOP_LIST = [
  { id: 0, name: 'Bay thẳng' },
  { id: 1, name: '1 điểm dừng' },
  { id: 2, name: '> 2 điểm dừng' },
];

export const GENDER_LIST = [
  {
    id: 'M',
    label: 'IDS_MT_TEXT_MR',
    label_1: 'IDS_MT_TEXT_MR_1',
  },
  {
    id: 'F',
    label: 'IDS_MT_TEXT_MRS',
    label_1: 'IDS_MT_TEXT_MRS_1',
  },
];

export const AGE_CATEGORY = [
  {
    id: 'ADULT',
    label: 'IDS_MT_TEXT_AGE_CATEGORY_ADULT',
  },
  {
    id: 'CHILD',
    label: 'IDS_MT_TEXT_AGE_CATEGORY_CHILD',
  },
  {
    id: 'INFANT',
    label: 'IDS_MT_TEXT_AGE_CATEGORY_INFANT',
  },
];

/**
 * Danh sách giá trị sắp xếp vé máy bay
 */
export const CUSTOMER_TYPE = {
  ADULT: 1,
  CHILDREN: 2,
  BABY: 3,
};
export const CUSTOMER_TYPE_TEXT = {
  ADULT: 'adult',
  CHILDREN: 'child',
  INFANT: 'infant',
};

export const flightPaymentStatus = {
  FAIL: 'fail',
  SUCCESS: 'success',
  CANCELLED_BY_PROVIDER: 'cancelled_by_provider',
  PENDING: 'pending',
  AWAITING_PAYMENT: 'awaiting-payment',
  WAITING_PAYMENT: 'waiting_payment',
  COMPLETED: 'completed',
  REFUNDED_POSTED: 'refunded-posted',
  REFUNDED: 'refunded',
  DISPUTED: 'disputed',
  WAITING: 'waiting',
  HOLDING: 'holding',
  CANCELLING_HOLDING: 'cancelling_holding',
  PAYMENT_SUCCESS: 'payment_success',
  IN_PROGRESS: 'in_progress',
};

export const flightPaymentMethodCode = {
  CREDIT: 'CD',
  VISA_MASTER: 'VM',
  QR_CODE: 'QR',
  ATM: 'ATM',
  HOLDING: 'PL',
  CASH: 'CA',
  BANK_TRANSFER: 'BT',
  BANK_TRANSFER_2: 'DBT',
};

export const genderLabel = {
  M: 'Nam',
  F: 'Nữ',
};

export const paymentTypes = {
  SUCCESS: 'success',
  FAIL: 'fail',
  HOLDING: 'holding',
  PENDING: 'pending',
};

export const airlinesName = [
  {
    id: 1,
    name: 'vietnam-airline',
  },
  {
    id: 2,
    name: 'bamboo',
  },
  {
    id: 3,
    name: 'pacific',
  },
  {
    id: 4,
    name: 'vietjet',
  },
];

export const airlineColor = [
  {
    id: 1,
    color: '#D59E2A',
  },
  {
    id: 2,
    color: '#64AF53',
  },
  {
    id: 3,
    color: '#00529b',
  },
  {
    id: 4,
    color: '#E5322B',
  },
];

export const defaultCountry = {
  id: 232,
  code: 'VN',
  name: 'Viet Nam',
  phonecode: 84,
};

export const bannerHome =
  'https://storage.googleapis.com/tripi-assets/mytour/banner/home-banner.jpg';
export const bannerHotel =
  'https://storage.googleapis.com/tripi-assets/mytour/banner/banner_hotel.jpg';
export const bannerFlight =
  'https://storage.googleapis.com/tripi-assets/mytour/banner/banner_flight.jpg';

export const LINK_FLIGHT_BOADING_PASS =
  'https://storage.googleapis.com/tripi-assets/mytour/images/boading-pass.gif';

export const prefixUrlIcon =
  'https://storage.googleapis.com/tripi-assets/mytour/icons/';
export const listIcons = {
  iconPlus: 'icon_plus.svg',
  IconAirPlane: 'icon_air_plane.svg',
  IconBestPrice: 'icon_best_price.svg',
  IconHotelBox: 'icon_hotel_box.svg',
  IconMPlus: 'icon_m_plus.svg',
  IconBoxMail: 'icon_mail_red.svg',
  IconMallBackground: 'icon_mall_background.svg',
  IconMytourBackground: 'icon_mytour_background.svg',
  IconMytourMallWhite: 'icon_mytour_mall_white.svg',
  IconPiggyDollar: 'icon_piggy_dollar.svg',
  IconRatingMall: 'icon_rating_mall.svg',
  IconRecommendRoom: 'icon_recommend_room.svg',
  IconStatusOrderHotel: 'icon_status_order_hotel.svg',
  IconSupport247: 'icon_support_247.svg',
  IconTagSale: 'icon_tag_sale.svg',
  IconBeach: 'icon_beach.svg',
  IconCalenderOrder: 'icon_calendar_order.svg',
  IconFlightBar: 'icon_flight_bar.svg',
  IconFlightBox: 'icon_flight_box.svg',
  IconPartnerDiscount: 'icon_partner_discount.svg',
  IconPartnerGuide: 'icon_partner_guide.svg',
  IconPartnerRegister: 'icon_partner_register.svg',
  IconPartnerStep1: 'icon_partner_step_1.svg',
  IconPartnerStep2: 'icon_partner_step_2.svg',
  IconPartnerStep3: 'icon_partner_step_3.svg',
  IconPartnerStep4: 'icon_partner_step_4.svg',
  IconPromo: 'icon_promo.svg',
  iconInstagram: 'icon_instagram.svg',
  iconMessenger: 'icon_messenger.svg',
  iconFacebook: 'icon_facebook.svg',
  IconPayment: 'icon_payment.svg',
  IconTotalHotel: 'icon_total_hotel.svg',
  IconFiveStar: 'icon_5_star.svg',
  IconBgLogout: 'icon_bg_logout.svg',
  IconBoxGif: 'icon_gif_box.svg',
  IconHiddenInstall: 'icon_install_logo_hidden.svg',
  IconMethodVisa: 'icon_method_visa.svg',
  IconPartnerInfo: 'icon_partner_info.svg',
  IconPriceTag2: 'icon_price_tag_2.svg',
  IconRefreshOnlyMytour: 'icon_refresh_only_mytour.svg',
  IconSynchronize: 'icon_synchronize.svg',
  IconTransportTicket: 'icon_transport_ticket.svg',
  IconTripadvisorMall: 'icon_trip_advisor_mall.svg',
  IconTripadvisor: 'icon_tripadvisor.svg',
  IconTripAdvisorReview: 'icon_tripadvisor_review.svg',
  IconDoubleSingleBed: 'icon__double_single_bed.svg',
  Icon3SingleBed: 'icon_3 single bed.svg',
  IconTagTheBest: 'icon_tag_the_best.svg',
  IconTagTravellers: 'icon_tag_travellers.svg',
  icon_notification_buy: 'notification/icon_notification_buy.svg',
  icon_notification_discount: 'notification/icon_notification_discount.svg',
  icon_notification_empty: 'notification/icon_notification_empty.svg',
  icon_notification_gift: 'notification/icon_notification_gift.svg',
  icon_notification_info: 'notification/icon_notification_info.svg',
  icon_notification_invoid: 'notification/icon_notification_invoid.svg',
  icon_notification_ring: 'notification/icon_notification_ring.svg',
  icon_notification_wallet: 'notification/icon_notification_wallet.svg',
  icon_trash_notification: 'notification/icon_trash_notification.svg',
  IconAvatarUserDefault: 'icon_avatar_user_default.svg',
  IconLogoMytour1: 'icon_logo_mytour_v1.svg',
  image_half_phone_mytour: 'image_half_phone_mytour.png',
  icon_hotel_download_new: 'icon_hotel_download_new.svg',
  icon_flight_download_new: 'icon_flight_download_new.svg',
};

export const STEP_NUMBER = {
  PAYMENT_STEP_1: 1,
  PAYMENT_STEP_2: 2,
  PAYMENT_STEP_3: 3,
};

export const ORDER_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  HOLDING: 'HOLDING',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  IN_PROGRESS: 'IN_PROGRESS',
  REFUNDED: 'REFUNDED',
  UNDEFINED: 'UNDEFINED',
};
export const LIST_STATUS = {
  PENDING: 'PENDING',
  SETTLED: 'SETTLED',
  FAILED: 'FAILED',
  HOLDING: 'HOLDING',
  BANK_TRANSFER: 'PR',
  AWAITING_PAYMENT: 'AWAITING-PAYMENT',
  UNDEFINED: 'UNDEFINED',
  NOT: 'NOT',
};

export const LIST_SCREEN_STATUS = {
  SUCCESS: 'SUCCESS', // Thành công
  SUCCESS_AND_EXPORTING: 'SUCCESS_AND_EXPORTING', // thanh toán thành công, đang xuất vé
  BOOKING_FAIL: 'BOOKING_FAIL', // đặt vé thất bại
  FAILED: 'FAILED', // thất bại
  HOLDING_FAIL: 'HOLDING_FAIL', // giữ chỗ thất bại
  HOLDING_SUCCESS: 'HOLDING_SUCCESS', // giữ chỗ thành công
  UNDEFINED: 'UNDEFINED', // không xác định
  WAITING_PAYMENT: 'WAITING_PAYMENT', // vé đang giữ, chờ chuyển khoản
  LOADING: 'LOADING', // loading
};

export const TIMEOUT_TIME = 1500;
export const MAX_CALL_API_COUNT = 10;

export const paymentStatus = {
  settled: 'settled',
  holding: 'holding',
  failed: 'failed',
  awaiting_payment: 'awaiting-payment',
};

export const flyxBookingStatus = {
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  HOLDING: 'HOLDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  REFUNDED: 'REFUNDED',
};
