import { Box, ButtonBase, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import { NEUTRAL_200, RED, WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import LanguageSwitcher from 'modules/common/LanguageSwitcher';
import useFlightBooking from 'modules/hooks/useFlightBooking';
import WayFlightItemDetail from 'modules/mobile/flight/payment/orderDetail/WayFlightItemDetail';
import moment from 'moment';
import { IconChevronRight } from 'public/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IS_MYTOUR,
  LIST_SCREEN_STATUS,
  ORDER_STATUS,
  flyxBookingStatus,
} from 'utils/constants';
import { checkWebview } from 'utils/helpers';
import BookingBankTranfer from './payment/BookingBankTranfer';
import BookingFail from './payment/BookingFail';
import BookingFailPaymentSuccess from './payment/BookingFailPaymentSuccess';
import BookingHoldingFail from './payment/BookingHoldingFail';
import BookingHoldingSucess from './payment/BookingHoldingSucess';
import BookingPending from './payment/BookingPending';
import BookingSucess from './payment/BookingSucess';
import BookingSucessAndExporting from './payment/BookingSucessAndExporting';
import BookingUndefined from './payment/BookingUndefined';
import OrderDetail from './payment/orderDetail/OrderDetail';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    background: WHITE,
    borderRadius: '0px 0px 20px 20px',
  },
  circles: {
    display: 'grid',
    gridTemplateColumns: 'repeat(24, 1fr)',
    placeItems: 'center',
    position: 'absolute',
    left: 20,
    right: 20,
    top: -2,
  },
  circle: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.blue.blue1,
  },
  boxCodeInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    minHeight: 80,
    padding: '16px 0',
    maxWidth: 630,
    margin: 'auto',
    position: 'relative',
  },
  oneColumn: {
    gridTemplateColumns: '1fr',
  },
  orderCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 4,
    textAlign: 'center',
  },
  codeLeft: {
    color: theme.palette.blue.blueLight8,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 600,
  },
  codeRight: {
    color: theme.palette.secondary.main,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 600,
  },
  ticketItem: {
    width: '100%',
    padding: '16px 0',
  },
}));

const Booking = () => {
  const {
    screenStatus,
    flyxBooking: orderDetail,
    paymentStatusDetail,
    agentsInfo,
  } = useFlightBooking();

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [viewOrderDetail, setViewOrderDetail] = useState(false);

  const toggleDrawerOrderDetail = (open) => () => {
    setViewOrderDetail(open);
  };

  if (!orderDetail || !paymentStatusDetail || !agentsInfo)
    return <LinearProgress />;

  const isTwoWay = orderDetail?.tickets?.length === 2;
  const bookingCode = orderDetail?.booking?.bookingCode;
  const outBoundReservationCode = orderDetail?.tickets?.[0]?.reservationCode;
  const inBoundReservationCode = isTwoWay
    ? orderDetail?.tickets?.[1]?.reservationCode
    : null;

  const airLinesInfo = orderDetail?.misc?.airlines;
  const airPortsInfo = orderDetail?.misc?.airports;

  const bankTransferInfo = paymentStatusDetail?.transferInfo;
  const bankTransferOptions = paymentStatusDetail?.transferOptions;

  const flightDetails = orderDetail?.tickets?.map((p) => p?.flightDetails);

  const linkWebsite = agentsInfo.website;

  const contact = orderDetail.contact;

  const browserType = checkWebview();
  const isWebview =
    browserType === 'ios_webview' || browserType === 'android_webview';

  const getScreenStatus = () => {
    switch (screenStatus) {
      case LIST_SCREEN_STATUS.SUCCESS:
        return <BookingSucess />;
      case LIST_SCREEN_STATUS.SUCCESS_AND_EXPORTING:
        return <BookingSucessAndExporting />;
      case LIST_SCREEN_STATUS.HOLDING_SUCCESS:
        return (
          <BookingHoldingSucess orderDetail={orderDetail} contact={contact} />
        );
      case LIST_SCREEN_STATUS.FAILED:
        return <BookingFail orderDetail={orderDetail} />;
      case LIST_SCREEN_STATUS.BOOKING_FAIL:
        return <BookingFailPaymentSuccess orderDetail={orderDetail} />;
      case LIST_SCREEN_STATUS.HOLDING_FAIL:
        return <BookingHoldingFail orderDetail={orderDetail} />;
      case LIST_SCREEN_STATUS.UNDEFINED:
        return <BookingUndefined />;
      case LIST_SCREEN_STATUS.LOADING:
        return <BookingPending />;
      case LIST_SCREEN_STATUS.WAITING_PAYMENT:
        return (
          <BookingBankTranfer
            bankTransferInfo={bankTransferInfo}
            bankTransferOptions={bankTransferOptions}
          />
        );
      default:
        return <BookingUndefined />;
    }
  };
  const hideReservationCode = !(
    IS_MYTOUR &&
    orderDetail?.booking?.bookingStatus === flyxBookingStatus.HOLDING
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.blue.blue1,
          minHeight: '100vh',
          padding: '16px',
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          style={{ position: 'absolute', top: 0, right: 2, zIndex: 1 }}
        >
          <LanguageSwitcher languageCode={orderDetail?.booking?.languageCode} />
        </Box>
        {getScreenStatus()}
        <Box className={classes.container}>
          {(moment().hour() >= 23 || moment().hour() <= 7) && (
            <Typography sx={{ color: RED, textAlign: 'center' }}>
              {t('IDS_TEXT_NOTE')}
            </Typography>
          )}
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              typeButton="text"
              width="fit-content"
              onClick={toggleDrawerOrderDetail(true)}
            >
              <Typography sx={{ marginRight: '8px', textTransform: 'none' }}>
                {t('IDS_TEXT_ORDER_DETAIL')}
              </Typography>
              <IconChevronRight
                className="svgFillAll"
                stroke={theme.palette.blue.blueLight8}
              />
            </Button>
            <OrderDetail
              open={viewOrderDetail}
              handleClose={toggleDrawerOrderDetail}
              orderDetail={orderDetail}
              airLinesInfo={airLinesInfo}
              airPortsInfo={airPortsInfo}
            />
          </Box>
          <div className={classes.divider} />
          <Box className={classes.ticketItem}>
            <WayFlightItemDetail
              item={orderDetail?.tickets[0]}
              airlineInfo={airLinesInfo?.find(
                (ele) =>
                  ele.code ===
                  orderDetail?.tickets[0]?.flightDetails[0]?.operatingAirline
              )}
              orderDetail={orderDetail}
              flightDetail={flightDetails[0]}
              airPortsInfo={airPortsInfo}
            />
            {isTwoWay && (
              <>
                <Box mt={2} className={classes.divider} />
                <Box mt={2}>
                  <WayFlightItemDetail
                    item={orderDetail?.tickets[1]}
                    airlineInfo={airLinesInfo?.find(
                      (ele) =>
                        ele.code ===
                        orderDetail?.tickets[1]?.flightDetails[0]
                          ?.operatingAirline
                    )}
                    orderDetail={orderDetail}
                    flightDetail={flightDetails[1]}
                    airPortsInfo={airPortsInfo}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box
          display={!hideReservationCode ? 'block !important' : 'grib'}
          className={clsx(
            classes.boxCodeInfo,
            ORDER_STATUS === ORDER_STATUS.FAILED && classes.oneColumn
          )}
        >
          <Box className={classes.circles}>
            {[...Array(24)].map((p, index) => (
              <Box className={classes.circle} />
            ))}
          </Box>
          <Box
            mt={!hideReservationCode ? '16px !important' : 'none'}
            className={classes.orderCode}
            sx={{
              borderRight: hideReservationCode && `1px solid ${NEUTRAL_200}`,
            }}
          >
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: theme.palette.black.black3,
              }}
            >
              {t('IDS_TEXT_FLIGHT_ORDER_CODE')}
            </Box>
            <div className={classes.codeLeft}>{bookingCode}</div>
          </Box>
          {ORDER_STATUS !== ORDER_STATUS.FAILED && hideReservationCode && (
            <>
              {isTwoWay ? (
                <Box className={classes.orderCode}>
                  <Box
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: theme.palette.black.black3,
                    }}
                  >
                    {`${t('IDS_TEXT_FLIGHT_BOOK_CODE_OUTBOUND')} `}
                  </Box>
                  <Box className={classes.codeRight}>
                    {outBoundReservationCode}
                  </Box>
                  <Box
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: theme.palette.black.black3,
                      marginTop: '8px',
                    }}
                  >
                    {`${t('IDS_TEXT_FLIGHT_BOOK_CODE_INBOUND')} `}
                  </Box>
                  <Box className={classes.codeRight}>
                    {inBoundReservationCode}
                  </Box>
                </Box>
              ) : (
                <Box className={classes.orderCode}>
                  <Box
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: theme.palette.black.black3,
                    }}
                  >
                    {`${t('IDS_TEXT_FLIGHT_BOOK_CODE')} `}
                  </Box>
                  <Box className={classes.codeRight}>
                    {outBoundReservationCode}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {!!linkWebsite && !isWebview && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.common.white,
            padding: '8px 16px',
          }}
        >
          <Link
            href={linkWebsite}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <ButtonBase
              sx={{
                background: theme.palette.secondary.main,
                borderRadius: '8px',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '19px',
                textAlign: 'center',
                width: '100%',
                color: '#FFFFFF',
                padding: '12px 16px',
                height: '48px',
              }}
            >
              {t('IDS_MT_TEXT_NOT_FOUND_403_DES_3')}
            </ButtonBase>
          </Link>
        </Box>
      )}
    </>
  );
};

export default Booking;
